import Collapse from 'Collapse';

/**
 * Dealer collapse functionality.
 *
 * @extends module:project/Common.Collapse
 * @memberof module:feature/Dealer
 * @version 1.0.0
 * @author Peter van Gennip <peter.van.gennip@valtech.nl>
 */
class DealerCollapse extends Collapse {

    constructor($element) {
        super($element);
        this.$collapseParent = this.$element.parents('.js-collapse-parent');
        this.$dataCloseExpanded = this.$collapseParent.attr('data-collapse-close-expanded');
        this.collapsibles = $('.collapse');
    }

    init() {
        super.init();
    }

    /**
     * Closes expanded collapsibles.
     */
    closeExpanded() {
        if (!this.$element.hasClass('is-open')) {
            this.$collapseParent.find('.js-dealer-collapse').removeClass('is-open');
            this.$collapseParent.find('.js-collapse-trigger').removeClass('is-open');
            this.collapsibles.collapse('hide');
        }
    }

    /**
     * Collapse/expand toggler
     */
    toggleCollapse(e) {
        e.preventDefault();

        if (typeof this.$dataCloseExpanded !== 'undefined') {
            this.closeExpanded();
        }

        if (!this.$element.hasClass('is-open')) {
            this.$element.addClass('is-open');
            this.$trigger.addClass('is-open');
            this.collapsible.collapse('show');
        } else {
            this.$element.removeClass('is-open');
            this.$trigger.removeClass('is-open');
            this.collapsible.collapse('hide');
        }
    }

}

// register to Component Handler
window.ComponentHandler.register({
    constructor: DealerCollapse,
    classAsString: 'DealerCollapse',
    cssClass: 'js-dealer-collapse'
});

export default DealerCollapse;